<template>
  <div class="BadgeColorPicker">
    <div class="picker" :class="{'picker-disabled': withSwitch && value.collapsed}">
      <div class="picker-title-wrapper">
        <div class="picker-title">{{ title }}</div>
        <label>
          <span v-if="withSwitch" class="switch mr-0">
            <input v-model="value.collapsed" type="checkbox">
            <span class="slider round"></span>
          </span>
        </label>
      </div>
      <div class="picker-input-wrapper">
        <base-textbox
          :value="getPickerValue(value.color)"
          @input="value.color = $event.split('#')[1] || ''"
          class="picker-input bordered m-0"
          :bordered="false"
          :maxLength="7"
        />
        <div class="picker-opacity">{{getOpacity(value.color)}}</div>
        <svg
          @click.stop="$emit('hide-picker')"
          class="colorpicker" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="4" :fill="'#' + value.color"/>
        </svg>
      </div>
      <div @click.stop>
        <color-picker
          v-if="value.isShowPicker"
          :value="{ hex: '#' + value.color }"
          class="color-picker"
          :class="{'color-picker-top' : top}"
          @input="
        (event) => {
          value.color = event.hex8.split('#')[1]
        }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'BadgeColorPicker',
  components: {
    'color-picker': Chrome
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    withSwitch: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    getPickerValue (value) {
      return '#' + value.substring(0, value.length === 8 ? value.length - 2 : value.length)
    },
    roughScale (x, base) {
      const parsed = parseInt(x, base)
      if (isNaN(parsed)) return 0
      return parsed * 100
    },
    percentage (partialValue, totalValue) {
      return (100 * partialValue) / totalValue
    },
    getOpacity (value) {
      if (value && value.length === 8) {
        let opacity16 = this.roughScale(value.slice(-2), 16)
        return parseInt(this.percentage(opacity16, 25500)) + '%'
      } else {
        return '100%'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.BadgeColorPicker {
  .picker {
    width: 100%;
    position: relative;
    &-disabled {
      opacity: 0.6;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 44px;
        bottom: 0;
        z-index: 20;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 0.05em;
      color: rgba(27, 24, 35, .5);
      text-transform: uppercase;
      &-wrapper {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .color-picker {
      position: absolute;
      z-index: 30;
      top: calc(100% + 5px);
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 13px 11px rgba(25, 34, 29, 0.1);
      &-top {
        top: -222px !important;
      }
    }
    ::v-deep {
      .base-text-box {
        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }
    &-opacity {
      position: absolute;
      right: 14px;
      margin-top: 2px;
      font-size: 13px;
    }
    &-input {
      @media (max-width: 500px) {
        width: 100%;
      }
      &-no-padding {
        ::v-deep {
          .input {
            padding-left: 12px;
          }
        }
      }
      &-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        .select {
          ::v-deep {
            .multiselect__single {
              max-width: calc(100% - 10px);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        svg {
          left: 14px;
          position: absolute;
          //box-shadow: 0 0 0 1px rgba(0,0,0,.14);
          border-radius: 4px;
          cursor: pointer;
          rect {
            stroke-width:1;
            stroke:rgba(0,0,0,.14);
          }
        }
      }
      ::v-deep {
        .input {
          padding-left: 44px;
        }
      }
    }
  }
}
</style>
