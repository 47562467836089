<template>
  <div class="BaseFileUpload">
    <label class="label" v-if="label" @click="selectFile">
      {{ label }}
    </label>
    <div class="d-flex align-items-center">
      <base-button class="upload-button" :color="color" @click="selectFile">
        {{ text }}
      </base-button>
      <div
        v-if="showDescription || (description && !image)"
        class="description description mt-0 pl-3 pb-1 opacity-6"
      >
        {{ description }} <b>{{sizes}}</b>
      </div>
    </div>
    <input type="file" ref="file" @change="onFileChange" :multiple="multiple" hidden>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      type: String
    },

    sizes: {
      required: false,
      type: String
    },

    defaultText: {
      required: false,
      type: String,
      default: 'Choose file'
    },

    description: {
      required: false,
      type: String
    },

    color: {
      required: false,
      type: String
    },

    multiple: {
      required: false,
      type: Boolean,
      default: false
    },

    showDescription: {
      required: false,
      type: Boolean,
      default: false
    },

    image: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      files: []
    }
  },

  methods: {
    selectFile () {
      this.$refs.file.click()
    },

    onFileChange (event) {
      this.files = !this.files
      this.files = event.target.files
    }
  },

  watch: {
    files () {
      if (this.files.length > 0) {
        this.$emit('select', this.files)
      } else {
        this.$emit('select', null)
      }
    }
  },

  computed: {
    text () {
      if (this.files.length === 0) {
        return this.defaultText
      }

      if (this.files.length === 1) {
        return this.files[0].name
      }

      return `Selected ${this.files.length} files`
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseFileUpload {
  position: relative;
  margin-bottom: 20px;

  .label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    opacity: .8;
    margin-bottom: 12px;
    letter-spacing: -0.022em;
    font-feature-settings: "ss04" off;
    color: #1B1823;
    line-height: 19px;
  }

  .description {
    margin-top: 4px;
    font-size: 12px;
    line-height: 17px;
    color: map-get($colors, gray-1);
    max-width: 140px;
    padding-left: 20px !important;
  }

  .upload-button {
    min-width: 120px;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: -0.022em !important;
    padding: 0 14px !important;
  }
}
</style>
