<template>
  <div class="StatusPageStyleSelect">
    <div class="title">{{ title }}</div>
    <div class="items">
      <div v-for="item in items" :key="item.id" class="item-wrapper">
        <div class="list-item-title">{{ item.title }}</div>
        <div class="item" :class="{'item-selected': item.selected}" @click="$emit('select', item.id)">
          <label class="radio">
            <input v-model="item.selected" type="radio" value="true">
            <span class="radio-label"></span>
          </label>
          <div class="item-content" v-html="item.html"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeStyleSelect',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    id: {
      type: Number,
      default: 0
    },
    isShowDefault: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.StatusPageStyleSelect {
  margin-top: 24px;
  .title {
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: rgba(27, 24, 35, .5);
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .items {
    display: flex;
    margin: 0;
    @media (max-width: 500px) {
      flex-direction: column;
      margin-top: 0;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 18px 14px 18px;
      margin: 0;
      background: #FFFFFF;
      box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
      border-radius: 6px;
      cursor: pointer;
      opacity: 0.5;
      @media (max-width: 500px) {
        margin: 0 0 15px;
        width: 100%;
      }
      &-wrapper {
        width: 33.33%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        @media (max-width: 500px) {
          width: 100%;
        }
      }
      &-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-disabled {
        cursor: default;
      }
      &-show {
        opacity: 1;
      }
      &-selected {
        opacity: 1;
      }
      .selected {
        position: absolute;
        right: -5px;
        bottom: -5px;
        background: #6551E0;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .radio {
    display: flex;
    align-items: center;
    width: 16px;
    margin-right: 14px;
    position: relative;
    input {
      display: none;
      &:checked + .radio-label:after {
        background: #6551E0;
      }
    }
    &-label {
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        border: 1px solid #E9EBEC;
        border-radius: 100%;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 4px;
        background: transparent;
        border-radius: 100%;
      }
    }
  }
}
</style>
