<template>
  <ValidationObserver ref="observer">
    <form class="StatusPageCustomizationForm" @submit.prevent="submit">
      <div class="style-settings">
        <StatusPageAccordion
          title="General Customization"
          class="pt-3"
          @open-accordion="setAccordion(1)"
          :isOpen="activeAccordion === 1"
        >
          <div v-if="form.fontStyle" class="d-flex align-items-center" :class="{'upload-disabled': !form.fontStyle.collapsed}">
            <StatusPageUpload
              :image="form.logo"
              label="Logo"
              default-text="Choose Logo"
              name="logo"
              class="light mb-2"
              description="Maximum displayed height is"
              sizes="34 px"
              @select="onLogoSelect"
            />

            <div class="logo-preview" v-if="form.logo">
              <img :src="form.logo" class="logo"/>

              <base-button
                v-if="form.logo"
                :outlined="true"
                color="danger"
                size="small"
                @click="deleteLogo"
              >
                Delete
              </base-button>
            </div>
          </div>

          <div v-if="form.fontStyle" class="item item-with-switch">
            <div class="title-wrapper">
              <label class="d-flex align-items-center" :class="{'switch-disabled': form.fontStyle.collapsed}">
              <span class="switch">
                <input v-model="form.fontStyle.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
                <span class="title switch-title">Use text as Logo</span>
              </label>
            </div>
            <div class="pb-4" v-if="!form.fontStyle.collapsed">
              <div class="list-item">
                <div class="list-item-input-wrapper list-item-text">
                  <base-textbox
                    v-model="form.fontStyle.logoText"
                    placeholder="Type Logo text"
                    class="list-item-input-no-padding bordered wide m-0"
                    :bordered="false"
                  />
                  <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="4" :fill="'#' + form.customText.operational"/>
                  </svg> -->
                </div>
              </div>
              <div class="list-flex-start">
                <div class="list-item">
                  <div class="list-item-title">font</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="family"
                        :searchable="true"
                        track-by="family"
                        v-model="form.fontStyle.font"
                        :options="fonts"
                      />
                      <icon-font-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <div class="list-item-title">weight</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="title"
                        :searchable="true"
                        track-by="id"
                        v-model="form.fontStyle.weight"
                        :options="fontWeights"
                      />
                      <icon-font-weight-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.fontStyle.color"
                    @hide-picker="hideColorPickers('fontStyle', 'color')"
                    title="color"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="form.fontStyle" class="d-flex align-items-center" style="margin-top: 20px">
            <StatusPageUpload
              :image="form.favicon"
              label="Favicon"
              default-text="Choose Favicon"
              name="favicon"
              class="light mb-2"
              description="Maximum displayed height is"
              sizes="196 px"
              @select="onFaviconSelect"
            />

            <div class="logo-preview" v-if="form.favicon">
              <img :src="form.favicon" class="logo"/>

              <base-button
                v-if="form.favicon"
                :outlined="true"
                color="danger"
                size="small"
                @click="deleteFavicon"
              >
                Delete
              </base-button>
            </div>
          </div>

          <div class="item-no-margin">
            <div class="list">
              <div class="list-item-full-width">
                <div class="list-item-title mt-3">link</div>
                <div class="list-item-input-wrapper mb-2">
                  <base-textbox
                    placeholder="https://"
                    v-model="form.logoLink"
                    class="list-item-input-no-padding bordered wide m-0"
                    :bordered="false"
                    description="Maximum 250 characters"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.logoSizes" class="item item-with-switch">
            <div class="title-wrapper">
              <label class="d-flex align-items-center" :class="{'switch-disabled': form.logoSizes.collapsed}">
              <span class="switch">
                <input v-model="form.logoSizes.collapsed" type="checkbox">
                <span class="slider round"></span>
              </span>
                <span class="title switch-title">Adjust Logo size</span>
              </label>
            </div>
            <div v-if="!form.logoSizes.collapsed" class="list-flex-start">
              <div class="list-item">
                <div class="list-item-title">WIDTH (MAX 200 PX)</div>
                <div class="list-item-input-wrapper">
                  <div class="website-wrapper">
                    <base-textbox
                      v-model="form.logoSizes.width"
                      class="list-item-input-pixel bordered wide m-0"
                      :bordered="false"
                    />
                    <div class="list-item-input-pixel-label">px</div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="list-item-title">HEIGHT (MAX 34 PX)</div>
                <div class="list-item-input-wrapper">
                  <div class="website-wrapper">
                    <base-textbox
                      v-model="form.logoSizes.height"
                      class="list-item-input-pixel bordered wide m-0"
                      :bordered="false"
                    />
                    <div class="list-item-input-pixel-label">px</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <StatusPageUpload
              :image="form.headerImage"
              label="Header Image"
              default-text="Choose Image"
              name="header_image"
              class="light mb-3"
              description="Recommended size is"
              sizes="1440x220 px"
              @select="onHeaderImageSelect"
              showDescription
            />

            <div class="logo-preview" v-if="form.headerImage">
              <img :src="form.headerImage" class="logo"/>

              <base-button
                v-if="form.headerImage"
                :outlined="true"
                color="danger"
                size="small"
                @click="deleteHeaderImage"
              >
                Delete
              </base-button>
            </div>
          </div>
          <base-checkbox label="Darken header image" v-model="form.darkenHeaderImage"/>
          <div v-if="form.colorStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Color Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.colorStyle.background"
                    @hide-picker="hideColorPickers('colorStyle', 'background')"
                    title="background"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.colorStyle.header"
                    @hide-picker="hideColorPickers('colorStyle', 'header')"
                    title="header"
                    withSwitch
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.linksColorStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Links Color Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.linksColorStyle.general"
                    @hide-picker="hideColorPickers('linksColorStyle', 'general')"
                    title="general"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.linksColorStyle.hover"
                    @hide-picker="hideColorPickers('linksColorStyle', 'hover')"
                    title="hover"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.textStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Text Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <div class="list-item-title">font</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="family"
                        :searchable="true"
                        track-by="family"
                        v-model="form.textStyle.font"
                        :options="fonts"
                      />
                      <icon-font-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.textStyle.color"
                    @hide-picker="hideColorPickers('textStyle', 'color')"
                    title="color"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.updatesButtonStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">"Get Updates" Button Style</span>
            </div>
            <div class="list-flex-start">
              <div>
                <div class="list-flex-start">
                  <div class="list-item">
                    <BadgeColorPicker
                      :value="form.updatesButtonStyle.color"
                      @hide-picker="hideColorPickers('updatesButtonStyle', 'color')"
                      title="text color"
                    />
                  </div>
                  <div class="list-item">
                    <BadgeColorPicker
                      :value="form.updatesButtonStyle.background"
                      @hide-picker="hideColorPickers('updatesButtonStyle', 'background')"
                      title="background"
                      withSwitch
                    />
                  </div>
                  <div class="list-item">
                    <BadgeColorPicker
                      :value="form.updatesButtonStyle.borderColor"
                      @hide-picker="hideColorPickers('updatesButtonStyle', 'borderColor')"
                      title="border color"
                      withSwitch
                    />
                  </div>
                </div>

                <div class="list-flex-start">

                <div class="list-item" :class="{'list-item-disabled': form.updatesButtonStyle.borderColor.collapsed}">
                  <div class="list-item-title">stroke</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-textbox
                        v-model="form.updatesButtonStyle.stroke"
                        class="list-item-input-pixel-with-icon bordered wide m-0"
                        :bordered="false"
                      />
                      <icon-stroke class="select-icon" width="16" height="16" />
                      <div class="list-item-input-pixel-label">px</div>
                    </div>
                  </div>
                </div>

                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.updatesButtonStyle.formButtonColor"
                    @hide-picker="hideColorPickers('updatesButtonStyle', 'formButtonColor')"
                    title="form button color"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.updatesButtonStyle.formButtonTextColor"
                    @hide-picker="hideColorPickers('updatesButtonStyle', 'formButtonTextColor')"
                    title="form button text color"
                  />
                </div>

                </div>

              </div>
            </div>
          </div>
          <div v-if="form.statusIconsStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Status Icons Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.statusIconsStyle.operational"
                    @hide-picker="hideColorPickers('statusIconsStyle', 'operational')"
                    title="Systems Operational"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.statusIconsStyle.partialSystemsOutage"
                    @hide-picker="hideColorPickers('statusIconsStyle', 'partialSystemsOutage')"
                    title="Partial Systems Outage"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.statusIconsStyle.majorSystemsOutage"
                    @hide-picker="hideColorPickers('statusIconsStyle', 'majorSystemsOutage')"
                    title="Major Systems Outage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.colorStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Custom CSS</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item-full-width w-100">
                  <codemirror
                    class="custom-css"
                    ref="myCm"
                    :options="cmOptions"
                    v-model="form.customCss"
                  ></codemirror>
                  <p style="font-size: 14px; opacity: 0.6">Maximum 2900 characters</p>
                </div>
              </div>
            </div>
          </div>
        </StatusPageAccordion>
        <hr class="accordion-row">
        <StatusPageAccordion
          title="Status"
          @open-accordion="setAccordion(2)"
          :isOpen="activeAccordion === 2"
        >
          <div class="item-no-margin">
            <div class="title-wrapper">
              <span class="title switch-title-show">Frame Style</span>
            </div>
            <div>
              <StyleSelect
                :items="styleItems.statusRoundness.items"
                @select="selectStyle(styleItems.statusRoundness.title, $event)"
              />
            </div>
          </div>
          <div v-if="form.statusFrameStyle" class="item-no-title">
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.statusFrameStyle.background"
                    @hide-picker="hideColorPickers('statusFrameStyle', 'background')"
                    title="background"
                    withSwitch
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.statusFrameStyle.borderColor"
                    @hide-picker="hideColorPickers('statusFrameStyle', 'borderColor')"
                    title="border color"
                    withSwitch
                  />
                </div>
                <div class="list-item" :class="{'list-item-disabled': form.statusFrameStyle.borderColor.collapsed}">
                  <div class="list-item-title">stroke</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-textbox
                        v-model="form.statusFrameStyle.stroke"
                        class="list-item-input-pixel-with-icon bordered wide m-0"
                        :bordered="false"
                      />
                      <icon-stroke class="select-icon" width="16" height="16" />
                      <div class="list-item-input-pixel-label">px</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.statusTextStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Text Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <div class="list-item-title">font</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="family"
                        :searchable="true"
                        track-by="family"
                        v-model="form.statusTextStyle.font"
                        :options="fonts"
                        topPosition
                      />
                      <icon-font-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <div class="list-item-title">weight</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="title"
                        :searchable="true"
                        track-by="id"
                        v-model="form.statusTextStyle.weight"
                        :options="fontWeights"
                        topPosition
                      />
                      <icon-font-weight-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.statusTextStyle.color"
                    @hide-picker="hideColorPickers('statusTextStyle', 'color')"
                    title="color"
                  />
                </div>
              </div>
            </div>
          </div>
        </StatusPageAccordion>
        <hr class="accordion-row">
        <StatusPageAccordion
          title="Uptime"
          @open-accordion="setAccordion(3)"
          :isOpen="activeAccordion === 3"
        >
          <div class="item-no-margin">
            <div class="title-wrapper">
              <span class="title switch-title-show">Uptime Style</span>
            </div>
            <div>
              <StyleSelect
                :items="styleItems.uptimeRoundness.items"
                @select="selectStyle(styleItems.uptimeRoundness.title, $event)"
              />
            </div>
          </div>
          <div v-if="form.uptimeStatesColorStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">States Color Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.uptimeStatesColorStyle.operational"
                    @hide-picker="hideColorPickers('uptimeStatesColorStyle', 'operational')"
                    title="operational"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.uptimeStatesColorStyle.unavailable"
                    @hide-picker="hideColorPickers('uptimeStatesColorStyle', 'unavailable')"
                    title="unavailable"
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.uptimeStatesColorStyle.investigation"
                    @hide-picker="hideColorPickers('uptimeStatesColorStyle', 'investigation')"
                    title="investigation"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.uptimeTextStyle" class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">Uptime (%) Font Style</span>
            </div>
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <div class="list-item-title">font</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="family"
                        :searchable="true"
                        track-by="family"
                        v-model="form.uptimeTextStyle.font"
                        :options="fonts"
                      />
                      <icon-font-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <div class="list-item-title">weight</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-dropdown
                        class="website website-with-icon select"
                        item-label="title"
                        :searchable="true"
                        track-by="id"
                        v-model="form.uptimeTextStyle.weight"
                        :options="fontWeights"
                      />
                      <icon-font-weight-badge class="select-icon" width="18" height="18" />
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.uptimeTextStyle.color"
                    @hide-picker="hideColorPickers('uptimeTextStyle', 'color')"
                    title="color"
                    top
                  />
                </div>
              </div>
            </div>
          </div>
        </StatusPageAccordion>
        <hr class="accordion-row">
        <StatusPageAccordion
          title="Incidents"
          @open-accordion="setAccordion(4)"
          :isOpen="activeAccordion === 4"
        >
          <div class="item-no-margin">
            <div class="title-wrapper">
              <span class="title switch-title-show">Frame Style</span>
            </div>
            <div>
              <StyleSelect
                :items="styleItems.incidentsFrameStyleRoundness.items"
                @select="selectStyle(styleItems.incidentsFrameStyleRoundness.title, $event)"
              />
            </div>
          </div>
          <div v-if="form.incidentsFrameStyle" class="item-no-title">
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.incidentsFrameStyle.background"
                    @hide-picker="hideColorPickers('incidentsFrameStyle', 'background')"
                    title="background"
                    withSwitch
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.incidentsFrameStyle.borderColor"
                    @hide-picker="hideColorPickers('incidentsFrameStyle', 'borderColor')"
                    title="border color"
                    withSwitch
                  />
                </div>
                <div class="list-item" :class="{'list-item-disabled': form.incidentsFrameStyle.borderColor.collapsed}">
                  <div class="list-item-title">stroke</div>
                  <div class="list-item-input-wrapper">
                    <div class="website-wrapper">
                      <base-textbox
                        v-model="form.incidentsFrameStyle.stroke"
                        class="list-item-input-pixel-with-icon bordered wide m-0"
                        :bordered="false"
                      />
                      <icon-stroke class="select-icon" width="16" height="16" />
                      <div class="list-item-input-pixel-label">px</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="title-wrapper">
              <span class="title switch-title-show">States Style</span>
            </div>
            <div>
              <StyleSelect
                :items="styleItems.incidentsStateStyleRoundness.items"
                @select="selectStyle(styleItems.incidentsStateStyleRoundness.title, $event)"
              />
            </div>
          </div>
          <div v-if="form.incidentsStateStyle" class="item-no-title">
            <div>
              <div class="list-flex-start">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.incidentsStateStyle.resolved"
                    @hide-picker="hideColorPickers('incidentsStateStyle', 'resolved')"
                    title="resolved"
                    top
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.incidentsStateStyle.exploring"
                    @hide-picker="hideColorPickers('incidentsStateStyle', 'exploring')"
                    title="exploring"
                    top
                  />
                </div>
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.incidentsStateStyle.update"
                    @hide-picker="hideColorPickers('incidentsStateStyle', 'update')"
                    title="update"
                    top
                  />
                </div>
              </div>
              <div class="list-flex-start mt-0">
                <div class="list-item">
                  <BadgeColorPicker
                    :value="form.incidentsStateStyle.identified"
                    @hide-picker="hideColorPickers('incidentsStateStyle', 'identified')"
                    title="identified"
                    top
                  />
                </div>
              </div>
            </div>
          </div>
        </StatusPageAccordion>
      </div>
      <div v-if="isSubscribed" class="toolbar">
        <base-button
          type="submit"
          ref="submit"
          class="success addNew"
          :loading="saving"
        >
          Save Page
        </base-button>

        <base-button
          type="button"
          class="addNew preview-button"
          @click="redirectToPreview"
        >
          Preview
        </base-button>

        <base-button
          type="button"
          class="is-outlined"
          ref="cancel"
          color="light"
          @click="restore"
        >
          Restore Default
        </base-button>
      </div>
      <div v-if="!isSubscribed" class="upgrade-wrapper">
        <UpgradeBadge text="Status Page Customization is available for paid customers only."/>
      </div>
      <div v-show="error" class="mt-4">
        <base-alert type="error">Something went wrong</base-alert>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import statusPagesApi from '@/api/statusPagesApi.js'
import BaseAlert from '@/components/Base/BaseAlert'
import StatusPageUpload from '@/components/StatusPage/StatusPageUpload'
import BadgeColorPicker from '@/components/Badge/BadgeColorPicker.vue'
import StatusPageAccordion from '../../StatusPage/StatusPageAccordion'
import StyleSelect from '@/components/StatusPage/StyleSelect.vue'
import IconStroke from '../../Icons/IconStroke'
import UpgradeBadge from '@/components/Common/UpgradeBadge'
import tippy from 'tippy.js'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/css/css.js'
import 'codemirror/theme/base16-light.css'
import 'codemirror/addon/selection/active-line.js'
import Swal from 'sweetalert2'

export default {
  components: {
    IconStroke,
    BaseAlert,
    StatusPageUpload,
    BadgeColorPicker,
    StatusPageAccordion,
    StyleSelect,
    codemirror,
    UpgradeBadge
  },
  props: {
    statusPage: {
      required: false,
      type: Object
    }
  },
  data () {
    return {
      logo: null,
      logoFile: null,
      logoStored: false,
      headerImage: null,
      headerImageFile: null,
      headerImageStored: false,
      favicon: null,
      faviconFile: null,
      faviconImageStored: false,
      saving: false,
      error: false,
      activeAccordion: 0,
      fonts: [],
      cmOptions: {
        tabSize: 2,
        mode: 'text/css',
        // theme: 'base16-light',
        lineNumbers: false,
        line: true
      },
      fontWeights: [
        {
          id: 1,
          title: 'Thin',
          value: 100
        },
        {
          id: 2,
          title: 'Light',
          value: 300
        },
        {
          id: 3,
          title: 'Regular',
          value: 400
        },
        {
          id: 4,
          title: 'Medium',
          value: 500
        },
        {
          id: 5,
          title: 'Semi Bold',
          value: 600
        },
        {
          id: 6,
          title: 'Bold',
          value: 700
        },
        {
          id: 7,
          title: 'Extra Bold',
          value: 800
        },
        {
          id: 8,
          title: 'Black',
          value: 900
        }
      ],
      defaultForm: {
        logo: null,
        headerImage: null,
        favicon: null,
        darkenHeaderImage: false,
        logoLink: '',
        logoSizes: {
          collapsed: true,
          width: '150',
          height: '34'
        },
        fontStyle: {
          collapsed: true,
          logoText: '',
          font: {
            id: 1,
            title: 'Inter',
            value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
          },
          weight: {
            id: 4,
            title: 'Medium',
            value: 500
          },
          color: {
            color: 'FFFFFF',
            type: 'color',
            isShowPicker: false
          }
        },
        colorStyle: {
          type: 'color',
          background: {
            color: 'FFFFFF',
            isShowPicker: false
          },
          header: {
            color: '19221D',
            isShowPicker: false,
            collapsed: true
          }
        },
        linksColorStyle: {
          type: 'color',
          general: {
            color: '19221D',
            isShowPicker: false
          },
          hover: {
            color: '6551E0',
            isShowPicker: false
          }
        },
        updatesButtonStyle: {
          type: 'color',
          color: {
            color: 'FFFFFF',
            isShowPicker: false
          },
          background: {
            color: '19221D',
            isShowPicker: false,
            collapsed: true
          },
          borderColor: {
            color: 'FFFFFF',
            isShowPicker: false,
            collapsed: false
          },
          stroke: '1',
          formButtonColor: {
            color: '1aaf5d',
            isShowPicker: false,
            collapsed: true
          },
          formButtonTextColor: {
            color: 'FFFFFF',
            isShowPicker: false
          }
        },
        statusIconsStyle: {
          collapsed: true,
          type: 'color',
          operational: {
            color: '219653',
            isShowPicker: false
          },
          partialSystemsOutage: {
            color: 'FFA500',
            isShowPicker: false
          },
          majorSystemsOutage: {
            color: 'FF0000',
            isShowPicker: false
          }
        },
        textStyle: {
          font: {
            id: 1,
            title: 'Inter',
            value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
          },
          color: {
            color: '19221D',
            isShowPicker: false,
            type: 'color'
          }
        },
        customCss: '',
        statusTextStyle: {
          collapsed: true,
          logoText: '',
          font: {
            id: 1,
            title: 'Inter',
            value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
          },
          weight: {
            id: 4,
            title: 'Medium',
            value: 500
          },
          color: {
            color: '19221D',
            type: 'color',
            isShowPicker: false
          }
        },
        uptimeStatesColorStyle: {
          collapsed: true,
          type: 'color',
          operational: {
            color: '219653',
            isShowPicker: false
          },
          unavailable: {
            color: 'A3A7A5',
            isShowPicker: false
          },
          investigation: {
            color: 'F2994A',
            isShowPicker: false
          }
        },
        uptimeTextStyle: {
          collapsed: true,
          logoText: '',
          font: {
            id: 1,
            title: 'Inter',
            value: '\'Inter\',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
          },
          weight: {
            id: 4,
            title: 'Medium',
            value: 500
          },
          color: {
            color: 'A3A7A5',
            type: 'color',
            isShowPicker: false
          }
        },
        statusFrameStyle: {
          roundness: {
            id: 2,
            selected: true,
            title: 'rounded',
            html: '<div class="status-round status-round-2" />',
            value: 6
          },
          background: {
            color: 'FFFFFF',
            type: 'color',
            isShowPicker: false,
            collapsed: false
          },
          borderColor: {
            color: 'FFFFFF',
            type: 'color',
            isShowPicker: false,
            collapsed: true
          },
          stroke: '1'
        },
        uptimeStyle: {
          roundness: {
            id: 2,
            selected: true,
            title: 'rounded',
            html: `
                <div class="status-uptime status-uptime-2">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>`,
            value: 2
          }
        },
        incidentsFrameStyle: {
          roundness: {
            id: 2,
            selected: true,
            title: 'rounded',
            html: '<div class="status-round status-round-2" />',
            value: 6
          },
          background: {
            color: 'FFFFFF',
            type: 'color',
            isShowPicker: false,
            collapsed: false
          },
          borderColor: {
            color: 'FFFFFF',
            type: 'color',
            isShowPicker: false,
            collapsed: true
          },
          stroke: '1'
        },
        incidentsStateStyle: {
          roundness: {
            id: 2,
            selected: true,
            title: 'rounded',
            html: '<div class="status-round-black status-round-2" />',
            value: 6
          },
          resolved: {
            color: '19221D',
            type: 'color',
            isShowPicker: false
          },
          exploring: {
            color: '9FA2A0',
            type: 'color',
            isShowPicker: false
          },
          update: {
            color: '19221D',
            type: 'color',
            isShowPicker: false
          },
          identified: {
            color: '9FA2A0',
            type: 'color',
            isShowPicker: false
          }
        }
      },
      form: {},
      styleItems: {
        statusRoundness: {
          title: 'statusRoundness',
          items: [
            {
              id: 1,
              selected: false,
              title: 'rectangular',
              html: '<div class="status-round status-round-1" />',
              value: 0
            },
            {
              id: 2,
              selected: true,
              title: 'rounded',
              html: '<div class="status-round status-round-2" />',
              value: 6
            },
            {
              id: 3,
              selected: false,
              title: 'round',
              html: '<div class="status-round status-round-3" />',
              value: 100
            }
          ]
        },
        uptimeRoundness: {
          title: 'uptimeRoundness',
          items: [
            {
              id: 1,
              selected: false,
              title: 'rectangular',
              html: `
                <div class="status-uptime status-uptime-1">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>`,
              value: 0
            },
            {
              id: 2,
              selected: true,
              title: 'rounded',
              html: `
                <div class="status-uptime status-uptime-2">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>`,
              value: 2
            },
            {
              id: 3,
              selected: false,
              title: 'round',
              html: `
                <div class="status-uptime status-uptime-3">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>`,
              value: 100
            }
          ]
        },
        incidentsFrameStyleRoundness: {
          title: 'incidentsFrameStyleRoundness',
          items: [
            {
              id: 1,
              selected: false,
              title: 'rectangular',
              html: '<div class="status-round status-round-1" />',
              value: 0
            },
            {
              id: 2,
              selected: true,
              title: 'rounded',
              html: '<div class="status-round status-round-2" />',
              value: 6
            },
            {
              id: 3,
              selected: false,
              title: 'round',
              html: '<div class="status-round status-round-3" />',
              value: 100
            }
          ]
        },
        incidentsStateStyleRoundness: {
          title: 'incidentsStateStyleRoundness',
          items: [
            {
              id: 1,
              selected: false,
              title: 'rectangular',
              html: '<div class="status-round-black status-round-1" />',
              value: 0
            },
            {
              id: 2,
              selected: true,
              title: 'rounded',
              html: '<div class="status-round-black status-round-2" />',
              value: 6
            },
            {
              id: 3,
              selected: false,
              title: 'round',
              html: '<div class="status-round-black status-round-3" />',
              value: 100
            }
          ]
        }
      }
    }
  },
  mounted () {
    let form = JSON.parse(localStorage.getItem('status_page_preview'))

    if (form && form.slug === this.statusPage.slug) {
      this.form = form
      this.selectStyle(this.styleItems.statusRoundness.title, form.statusFrameStyle.roundness.id)
      this.selectStyle(this.styleItems.uptimeRoundness.title, form.uptimeStyle.roundness.id)
      this.selectStyle(this.styleItems.incidentsFrameStyleRoundness.title, form.incidentsFrameStyle.roundness.id)
      this.selectStyle(this.styleItems.incidentsStateStyleRoundness.title, form.incidentsStateStyle.roundness.id)
      localStorage.removeItem('status_page_preview')
    } else {
      if (this.statusPage.status_pages_style) {
        for (let key in this.statusPage.status_pages_style) {
          let formKey = key
            .split('_')
            .map((item, index) => index === 0 ? item : item.charAt(0).toUpperCase() + item.slice(1))
            .join('')
          if (
            (typeof this.statusPage.status_pages_style[key] === 'string' && this.statusPage.status_pages_style[key].includes('{"')) ||
            key === 'logo' || key === 'header_image' || key === 'custom_css' || key === 'logo_link' || key === 'favicon'
          ) {
            this.form[formKey] = JSON.parse(this.statusPage.status_pages_style[key]) || this.defaultForm[formKey]
          } else {
            this.form[formKey] = this.statusPage.status_pages_style[key] || this.defaultForm[formKey]
          }
        }
        if (!this.form.logoLink) {
          this.form.logoLink = this.defaultForm.logoLink
        }
        if (!this.form.updatesButtonStyle) {
          this.form.updatesButtonStyle = this.defaultForm.updatesButtonStyle
        }
        if (!this.form.incidentsStateStyle.identified) {
          this.form.incidentsStateStyle.identified = this.defaultForm.incidentsStateStyle.identified
        }
        this.form = { ...this.form }
        this.form.statusFrameStyle && this.selectStyle(this.styleItems.statusRoundness.title, this.form.statusFrameStyle.roundness.id)
        this.form.uptimeStyle && this.selectStyle(this.styleItems.uptimeRoundness.title, this.form.uptimeStyle.roundness.id)
        this.form.incidentsFrameStyle && this.selectStyle(this.styleItems.incidentsFrameStyleRoundness.title, this.form.incidentsFrameStyle.roundness.id)
        this.form.incidentsStateStyle && this.selectStyle(this.styleItems.incidentsStateStyleRoundness.title, this.form.incidentsStateStyle.roundness.id)
      } else {
        this.form = JSON.parse(JSON.stringify(this.defaultForm))
      }
    }
    this.loadFonts()
  },
  methods: {

    async failedImageUpload () {
      await Swal.fire({
        title: 'Failed',
        text: `Please check the requirements for the uploaded file.`,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    },

    showUpdateSuccess () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'Status page styles have been saved',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    showUpdateFailed () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: this.error,
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 5000)
    },

    setAccordion (value) {
      if (this.activeAccordion === value) {
        this.activeAccordion = 0
      } else {
        this.activeAccordion = value
      }
    },
    redirectToPreview () {
      this.setRoundness()
      this.form.statusPageId = this.$route.params.id
      this.form.slug = this.statusPage.slug
      localStorage.setItem('status_page_preview', JSON.stringify(this.form))
      let routeData = this.$router.resolve({ name: 'status.overview', params: { slug: this.statusPage.slug }, query: { preview: true } })
      window.open(routeData.href, '_blank')
    },
    restore () {
      this.form = JSON.parse(JSON.stringify(this.defaultForm))
      let font = this.fonts.find(item => item.family === 'Inter')
      this.form.fontStyle.font = font
      this.form.textStyle.font = font
      this.form.statusTextStyle.font = font
      this.form.uptimeTextStyle.font = font
      this.selectStyle(this.styleItems.statusRoundness.title, 1)
      this.selectStyle(this.styleItems.uptimeRoundness.title, 2)
      this.selectStyle(this.styleItems.incidentsFrameStyleRoundness.title, 1)
      this.selectStyle(this.styleItems.incidentsStateStyleRoundness.title, 1)
    },
    setRoundness () {
      this.form.statusFrameStyle.roundness = this.styleItems.statusRoundness.items.find(item => item.selected)
      this.form.uptimeStyle.roundness = this.styleItems.uptimeRoundness.items.find(item => item.selected)
      this.form.incidentsFrameStyle.roundness = this.styleItems.incidentsFrameStyleRoundness.items.find(item => item.selected)
      this.form.incidentsStateStyle.roundness = this.styleItems.incidentsStateStyleRoundness.items.find(item => item.selected)
    },
    async submit () {
      try {
        this.saving = true
        this.setRoundness()
        let data = { ...this.form }
        await statusPagesApi.saveStyles(data, this.$route.params.id)
        this.showUpdateSuccess()
        this.saving = false
      } catch (error) {
        this.error = error.response.data.message ?? 'Something went wrong'
        this.showUpdateFailed()
        this.saving = false
      }
    },
    selectStyle (formKey, id) {
      let itemIndex = this.styleItems[formKey].items.findIndex(item => item.id === id)
      if (!itemIndex && itemIndex !== 0) return
      this.styleItems[formKey].items = this.styleItems[formKey].items.map(item => {
        item.selected = false
        return item
      })
      this.styleItems[formKey].items[itemIndex].selected = true
    },
    hideColorPickers (selectedFormKey, selectedKey) {
      let isShowPicker = null
      if (selectedFormKey) {
        isShowPicker = !this.form[selectedFormKey][selectedKey].isShowPicker
      }
      for (let formKey in this.form) {
        for (let key in this.form[formKey]) {
          if (
            this.form[formKey][key] &&
            typeof this.form[formKey][key] === 'object' &&
            ((typeof this.form[formKey] === 'object' && this.form[formKey].type === 'color') ||
            this.form[formKey][key].type === 'color')
          ) {
            this.form[formKey][key].isShowPicker = false
          }
        }
      }
      if (selectedFormKey) {
        this.form[selectedFormKey][selectedKey].isShowPicker = isShowPicker
      }
    },
    async loadFonts () {
      let fonts = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.VUE_APP_GOOGLE_FONTS_KEY}`)
      fonts = await fonts.json()
      this.fonts = fonts.items.map(item => {
        item.value = `${item.family}, ${item.category}`
        return item
      })
      let font = this.fonts.find(item => item.family === 'Inter')
      if (!this.form.fontStyle.font.family) {
        this.form.fontStyle.font = font
      }
      if (!this.form.textStyle.font.family) {
        this.form.textStyle.font = font
      }
      if (!this.form.statusTextStyle.font.family) {
        this.form.statusTextStyle.font = font
      }
      if (!this.form.uptimeTextStyle.font.family) {
        this.form.uptimeTextStyle.font = font
      }
    },
    async onLogoSelect (files) {
      if (!files) {
        this.form.logo = null
        this.logoFile = null
        return
      }

      this.logoFile = files[0]

      let reader = new FileReader()

      if (this.logoFile) {
        let response = await statusPagesApi.storeStyleLogo(this.statusPage.id, this.logoFile)
        if (response === 'error') {
          await this.failedImageUpload()
        } else {
          let image = response.data
          this.form.logo = `${process.env.VUE_APP_BASE_URL}uploads/${image.path}`
          this.logoStored = true
        }
      }

      reader.readAsDataURL(this.logoFile)
    },
    async onFaviconSelect (files) {
      if (!files) {
        this.form.favicon = null
        this.faviconFile = null
        return
      }

      this.faviconFile = files[0]

      let reader = new FileReader()

      if (this.faviconFile) {
        let response = await statusPagesApi.storeStyleFaviconImage(this.statusPage.id, this.faviconFile)
        if (response === 'error') {
          await this.failedImageUpload()
        } else {
          let image = response.data
          this.form.favicon = `${process.env.VUE_APP_BASE_URL}uploads/${image.path}`
          this.faviconImageStored = true
        }
      }

      reader.readAsDataURL(this.faviconFile)
    },
    async onHeaderImageSelect (files) {
      if (!files) {
        this.form.headerImage = null
        this.headerImageFile = null
        return
      }

      this.headerImageFile = files[0]

      let reader = new FileReader()

      if (this.headerImageFile) {
        let response = await statusPagesApi.storeStyleHeaderImage(this.statusPage.id, this.headerImageFile)
        if (response === 'error') {
          await this.failedImageUpload()
        } else {
          let image = response.data
          this.form.headerImage = `${process.env.VUE_APP_BASE_URL}uploads/${image.path}`
          this.headerImageStored = true
        }
      }

      reader.readAsDataURL(this.headerImageFile)
    },
    async deleteLogo () {
      await statusPagesApi.deleteStyleLogo(this.statusPage.id)
      this.logoStored = true
      this.form.logo = null
      this.logoFile = null
    },
    async deleteFavicon () {
      await statusPagesApi.deleteStyleFaviconImage(this.statusPage.id)
      this.faviconImageStored = true
      this.form.favicon = null
      this.faviconFile = null
    },
    async deleteHeaderImage () {
      await statusPagesApi.deleteStyleHeaderImage(this.statusPage.id)
      this.headerImageStored = true
      this.form.headerImage = null
      this.headerImageFile = null
    }
  },
  computed: {
    isSubscribed () {
      return this.user.subscription_plan && this.user.subscription_plan.name !== 'Free'
    },
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.StatusPageCustomizationForm {
  .header {
    font-size: 18px;
    margin-bottom: 10px;
  }

  ::v-deep {
    .dropdown-top {
      .multiselect__content-wrapper {
        top: unset !important;
        bottom: 44px !important;
        border-radius: 5px 5px 0 0 !important;
      }
    }

    .c-accordion:first-of-type .c-accordion-header {
      padding-top: 10px !important;
    }
  }

  .website-wrapper {
    .select-icon {
      z-index: 8 !important;
    }
  }

  .logo-preview {
    display: flex;
    //flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;

    .logo {
      max-width: 110px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 5px;
      background-color: #19221d;
      max-height: 44px;
    }
  }

  .label {
    display: block;
    margin-bottom: 6px;
    color: map-get($colors, gray-1);
    font-size: 14px;
    font-weight: 600;
  }

  .toolbar {
    position: sticky;
    bottom:0;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 0;
    backdrop-filter: blur(3px);
    z-index: 29;
  }

  .switch {
    &-title {
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 19px !important;

      &-show {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 19px !important;
        letter-spacing: -0.022em !important;
        @extend .switch-title;
      }
    }
    &-disabled {
      opacity: 0.6;
    }
  }

  .accordion-row {
    border-top: 0;
    border-bottom: 1px solid #D6D9DA;
    margin: 0 !important;
  }

  .item {
    margin-top: 32px !important;
  }

  .item-with-switch {
    margin-top: 20px !important;
    padding: 0 !important;
  }

  .list-item {
    @media (max-width: 500px) {
      margin-top: 16px !important;
    }
    &-text {
      width: 330px;
    }
    &-disabled {
      opacity: 0.6;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 44px;
        bottom: 0;
        z-index: 20;
      }
    }
  }

  .upload-disabled {
    opacity: 0.6;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 20;
    }
  }
  .preview-button {
    background: #333333;
    color: white;
  }
  .upgrade {
    &-wrapper {
      display: none;
      justify-content: center;
      margin-bottom: 24px;
      @media (max-width: 1200px) {
        display: flex;
      }
    }
  }
  .custom-css {
    border-radius: 6px;
    //box-shadow: 0 2px 4px rgb(25 34 29 / 5%);
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
    border: 1px solid #E9EBEC;
    padding: 6px 9px;
    background: #fff;

    .CodeMirror {
      background: #fff !important;
    }
  }
}
</style>
