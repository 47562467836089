<template>
  <div class="card">
    <div class="card-body">
      <div class="general-settings general-customization StatusPageCustomizationPage">
        <div class="row">
          <div class="col-md-7 StatusPageCustomizationForm-wrapper">
            <StatusPageCustomizationForm ref="StatusPageCustomizationForm" :statusPage="statusPage"/>
          </div>
          <div v-if="!isSubscribed" class="upgrade-wrapper">
            <div class="upgrade-inner">
              <UpgradeBadge text="Status Page Customization is available for paid customers only."/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusPageCustomizationForm from '@/components/Forms/StatusPageForm/StatusPageCustomizationForm.vue'
import UpgradeBadge from '@/components/Common/UpgradeBadge'

export default {
  components: {
    StatusPageCustomizationForm,
    UpgradeBadge
  },

  metaInfo: {
    title: 'General Customization'
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  async mounted () {
    let container = document.getElementsByClassName('general-customization')[0]
    container.addEventListener('click', () => {
      if (this.$refs.StatusPageCustomizationForm) {
        this.$refs.StatusPageCustomizationForm.hideColorPickers()
      }
    })
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),
    isSubscribed () {
      return this.user.subscription_plan && this.user.subscription_plan.name !== 'Free'
    }
  }
}
</script>

<style lang="scss" scoped>
.StatusPageCustomizationPage {
  .StatusPageCustomizationForm {
    &-wrapper {
      @media (max-width: 1400px) {
        max-width: 60%;
        flex: 0 0 60%;
      }
      @media (max-width: 1200px) {
        max-width: 70%;
        flex: 0 0 70%;
      }
      @media (max-width: 1100px) {
        max-width: 85%;
        flex: 0 0 85%;
      }
      @media (max-width: 950px) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  .upgrade {
    &-wrapper {
      width: 40%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-end;
      padding: 32px 15px 0;
      @media (max-width: 1200px) {
        display: none;
      }
    }
    &-inner {
      position: sticky;
      top: 80px;
    }
  }
}
</style>
