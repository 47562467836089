<template>
  <div class="c-accordion">
    <div :id="title.toLowerCase()" class="c-accordion-scroll"></div>
    <a :href="'#' + title.toLowerCase()" ref="link"></a>
    <div class="c-accordion-header" @click.prevent="open">
      <span>{{ title }}</span>
      <img :src="require('@/assets/images/arrow-down.svg')" alt="arrow" class="icon" :class="{'icon-up' : isOpen}">
    </div>
    <div class="c-accordion__list" :class="{'is-expanded': isOpen}" ref="body">
      <div class="c-accordion-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusPageAccordion',
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open () {
      this.$emit('open-accordion')
      setTimeout(() => {
        if (this.isOpen) {
          this.$refs.link.click()
        }
      }, 500)
    }
  },
  watch: {
    isOpen () {
      let body = this.$refs.body
      if (this.isOpen) {
        setTimeout(() => {
          body.style.overflow = 'visible'
        }, 300)
      } else {
        body.style.overflow = 'hidden'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c-accordion {
  &-header {
    padding: 32px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &-content {
    padding-bottom: 32px;
  }
  .icon {
    &-up {
      transform: rotate(180deg);
    }
  }
  &-scroll {
    position: relative;
    top: -60px;
  }
  &__list {
    overflow: hidden;
    max-height: 0;
    transition: max-height .5s ease;
    padding: 0 !important;
    margin: 0;
    &.is-expanded {
      max-height: 2000px;
      transition: max-height .8s ease;
    }
  }
}
</style>
